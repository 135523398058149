/* src/componentes/WhatsAppFloat.css */
.whatsapp-float-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .whatsapp-float {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .whatsapp-float .MuiIconButton-root {
    background: linear-gradient(90deg, #25D366, #128C7E);
    color: white;
    padding: 5px;
    border-radius: 10px;
    height: 50px;
    width: 50px;
  }
  
  .whatsapp-float:hover .MuiIconButton-root {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .info-box {
    display: none;
    position: absolute;
    left: -150px; /* Despliega el texto a la izquierda */
    background: linear-gradient(90deg, #25D366, #128C7E);
    color: white;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
    width: 150px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .whatsapp-float:hover .info-box {
    display: block;
    opacity: 1;
  }
  
  .whatsapp-image {
    width: 85px; /* Ajusta el tamaño de la imagen */
    height: 85px;
  }