.reservar-page {
    background-image: url('../imagenes/form2.jpg'); /* Ruta a tu imagen de fondo */
    background-size: cover; /* Cubre todo el fondo del contenedor */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que se repita la imagen */
    min-height: 100vh; /* Asegura que cubra toda la pantalla */
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    opacity: 1 0;
}

.title-container {
    background: #1237B3;
    padding: 10px;
    color: #fff;
    text-align: center;
    font-weight: 800;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: 10px;
    margin-top: -5px;
    }

    .form-container {
        justify-content: center;
        background-color: rgba(76, 159, 253, 0.5);
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;
        margin: 10px ; /* Solo margen superior e inferior */
        position: relative;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
        margin-left: 100px; /* Puedes ajustar este valor para moverlo más a la derecha */
    }
    

    

h1 {
    margin-bottom: 1.5rem;
    color: #ffffff;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
}

input, select {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    font-style: italic;

}

input::placeholder {
    color: #888;
    font-style: italic;
}

select {
    color: #888;
    font-style: italic;
}

.button-Reservar {
    border-radius: 32px;
    padding: 7px 32px;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    font-weight: bolder;
    color: white;
    font-family: 'Libre Franklin', sans-serif;
    font-style: italic;
    margin: auto;
    margin-top: auto;
    display: block;
    font-size: 18px;
    background-color:#1237B3;
}

.button-Reservar:hover {
    color: rgb(238, 245, 240);
}

/* Para pantallas de hasta 768px de ancho (tablets y móviles) */
@media (max-width: 768px) {
    .form-container {
        max-width: 90%; /* El formulario ocupará el 90% del ancho de la pantalla */
        padding: 1rem; /* Reduce el padding en pantallas más pequeñas */
        margin: 1rem auto; /* Ajusta el espaciado */
    }

    .reservar-page {
        min-height: 80vh; /* Reduce la altura mínima para pantallas pequeñas */
        background-size: cover; /* Asegura que la imagen cubra el fondo */
    }
}

/* Para pantallas de hasta 480px de ancho (móviles pequeños) */
@media (max-width: 480px) {
    .form-container {
        max-width: 100%; /* El formulario ocupa todo el ancho */
        padding: 0.75rem; /* Reduce aún más el padding */
    }

    .reservar-page {
        min-height: 70vh; /* Ajusta la altura mínima en móviles pequeños */
    }

    h1 {
        font-size: 1.2rem; /* Ajusta el tamaño de las letras en títulos */
    }

    input, select, button {
        font-size: 0.9rem; /* Ajusta el tamaño de los inputs */
    }
}
