/* src/estilos/Slider.css */

/* Ajustes para pantallas grandes */
.slider-container {
  width: 100vw;
  height: 80vh;  /* Ajusta la altura del contenedor */
  overflow: hidden;
  background-color: #f0f0f0;  /* Fondo gris claro */
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Mantén la imagen cubriendo el slider en pantallas grandes */
}

/* Ajustes para dispositivos medianos */
@media (max-width: 768px) {
  .slider-container {
    height: 40vh; /* Disminuye la altura para tablets */
  }

  .image-gallery-slide img {
    object-fit: contain; /* Evita que las imágenes se recorten en dispositivos medianos */
    height: auto; /* Asegura que la altura se ajuste según la proporción */
  }
}

/* Ajustes para dispositivos pequeños */
@media (max-width: 480px) {
  .slider-container {
    height: 50vh; /* Reduce la altura para móviles */
  }

  .image-gallery-slide img {
    object-fit: contain; /* Muestra la imagen completa sin recortarla */
    height: auto;  /* Asegura que la imagen se ajuste proporcionalmente */
  }
}
