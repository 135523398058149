
.fondo {
    /* Asegúrate de que la ruta sea correcta */
    
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    background-position: center; /* Centra la imagen en el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    height: 600px; /* Ajusta la altura según tus necesidades */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
     /* Cambia el color del texto si es necesario */
    width: 100%; /* Asegura que el div ocupe todo el ancho disponible */
    height: 100vh; /* Ajusta la altura según sea necesario; en este caso ocupa toda la altura de la ventana */

}
